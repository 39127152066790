<template>
  <CRow>
    <CModal :title="
      submitType === 'Update' ? $lang.gac.crud.edit : $lang.gac.crud.create
    " size="lg" :show.sync="largeModal" :no-close-on-backdrop="true" :hide-footer="true" color="primary">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
        err_msg
      }}
      </CAlert>
      <CForm novalidate @submit.prevent="onSubmit">
        <CCardBody>
          <div class="form-group" :class="{ 'form-group--error': $v.gac.name.$error }">
            <label class="form__label">{{ $lang.gac.form.name }}
              <required_span />
            </label>
            <input :maxlength="maxlength.name" :placeholder="$lang.gac.form.name" class="form-control"
              v-model="gac.name" />
            <small class="error" v-if="$v.gac.name.$error && !$v.gac.name.required">{{
              $lang.gac.validation.required.name
            }}</small>
          </div>
          <div class="form-group">
            <label class="form__label">{{ $lang.gac.form.type }}
              <required_span />
            </label>
            <v-select id="type" :options="typeOptions" v-model="gac.type"></v-select>
            <small class="error" v-if="$v.gac.$error && !$v.gac.type.required">{{
              $lang.gac.validation.required.type
            }}</small>
          </div>
          <div class="form-group">
            <label class="form__label">{{ $lang.gac.form.industry }}
              <required_span />
            </label>
            <v-select id="industry" :options="industryOptions" label="title" v-model="gac.industry"></v-select>
            <small class="error" v-if="$v.gac.$error && !$v.gac.industry.required">{{
              $lang.gac.validation.required.industry
            }}</small>
          </div>
          <div class="form-group">
            <label class="form__label">{{ $lang.gac.form.group_type }}
              <required_span />
            </label>
            <v-select id="group_type" :options="groupTypeOptions" v-model="gac.group_type"></v-select>
            <small class="error" v-if="$v.gac.$error && !$v.gac.group_type.required">{{
              $lang.gac.validation.required.group_type
            }}</small>
          </div>
          <div class="form-group" :class="{ 'form-group--error': '' }">
            <label class="form__label">{{ $lang.gac.form.status }} </label>
            <input type="checkbox" :placeholder="$lang.companytype.form.isActive" :value="1" @change="changedIsActive"
              v-model="gac.status" />
          </div>
        </CCardBody>
        <CCardFooter>
          <CButton type="submit" size="sm" color="primary" :disabled="submitted">
            <CIcon name="cil-check-circle" />
            {{ $lang.buttons.general.crud.submit }}
          </CButton>
        </CCardFooter>
      </CForm>
      <template #footer style="display: none">
        <CButton @click="darkModal = false" color="danger" style="display: none">Discard
        </CButton>
        <CButton @click="darkModal = false" color="success" style="display: none">Accept
        </CButton>
      </template>
    </CModal>

    <CCol col="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.gac.title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButton color="primary" v-on:click="createTableRow" v-if="checkPermission('create-gac-categories')"
                  style="margin-right: 10px">
                  {{ $lang.buttons.general.crud.create }}
                </CButton>
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton color="danger" v-c-tooltip="$lang.buttons.export.pdf"
                    v-on:click="exports(self, 'pdf', module, 'GAC Categories')">
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv class="btn btn-success" v-c-tooltip="$lang.buttons.export.excel" :json-data="json_data"
                    :labels="json_label" :csv-title="json_title">
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter :filterShow="toggleFilterValue" @getFilterToggle="switchFilter"></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">Type</label>
                  <v-select :options="typeOptions" v-model="gacFilter.type">
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">Group Type</label>
                  <v-select :options="groupTypeOptions" v-model="gacFilter.groupType">
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">Industry</label>
                  <v-select :options="industryOptions" v-model="gacFilter.industry" label="title">
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton type="submit" size="sm" color="primary" @click="applyFilter()"
                    v-c-tooltip="$lang.common.filter.button.filter">
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton color="danger" size="sm" @click="resetFilter()"
                    v-c-tooltip="$lang.common.filter.button.cancel">
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>
          <CAlert :color="messageColor" closeButton :show.sync="dismissCountDownS" fade>{{ alertMessage }}
          </CAlert>
          <v-server-table :columns="columns" url="/ga/categories/list" :options="options" ref="myTable"
            @loaded="exports(self, 'Excel', module, 'GAC Categories')">
            <template #status="data">
              <CBadge :color="statusBudget(data.row.status)">{{ data.row.status }}</CBadge>
            </template>
            <template #name="data">
              <span :title="data.row.name">{{
                trimfunction(data.row.name, 50)
              }}</span>
            </template>
            <template #actions="data">
              <CButton color="success" v-c-tooltip="$lang.buttons.general.crud.edit"
                v-on:click="editTableRow(data.row.id)" v-if="checkPermission('edit-gac-categories')">
                <i class="fas fa-pencil-alt"></i>
              </CButton>
              <CButton color="danger" v-c-tooltip="$lang.buttons.general.crud.delete"
                v-on:click="deletePopup(data.row.id)" v-if="checkPermission('delete-gac-categories')">
                <i class="fas fa-trash"></i>
              </CButton>
              <!--              <CButton-->
              <!--                color="warning"-->
              <!--                v-c-tooltip="$lang.gac.detail.post"-->
              <!--                v-on:click="viewPostTableRow(data.row.id)"-->
              <!--                v-if="checkPermission('view-gac-categories-post')"-->
              <!--              >-->
              <!--                <i class="fas fa-eye"></i>-->
              <!--              </CButton>-->
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import { gAC, industry } from "/src/store/url.js";
import { Mixin } from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import { required } from "vuelidate/lib/validators";
import VueToasted from "vue-toasted";
import required_span from "../../components/layouts/general/required-span";
import { ServerTable } from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import { Maxlength } from "../../store/maxlength";
import BeforeFilter from "../BeforeFilterOpen";

function toast({ title, message, type, timeout, cb }) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);

export default {
  name: "giveAndAskCategories",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
    BeforeFilter
  },
  data() {
    return {
      toggleFilterValue: false,
      submitted: false,
      json_data: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      gac: {
        name: "",
        status: "",
        type: "",
        group_type: "",
        industry: ""
      },
      gacFilter: {
        type: "",
        groupType: "",
        industry: ""
      },
      industryModule: industry,
      industryOptions: [],
      typeOptions: [
        {
          'id': 'Company',
          'label': 'Company',
        },
        {
          'id': 'College',
          'label': 'College',
        },
      ],
      groupTypeOptions: [
        {
          'id': 'Founder',
          'label': 'Founder',
        },
        {
          'id': 'Management',
          'label': 'Management',
        },
        {
          'id': 'Freelancer',
          'label': 'Freelancer',
        },
      ],
      maxlength: {
        name: Maxlength.gac.name,
      },
      columns: ["name", "type", "groupType", "industryName", "status"],
      data: [],
      options: {
        headings: {
          name: this.$lang.gac.table.name,
          type: this.$lang.gac.table.type,
          status: this.$lang.gac.table.status,
          groupType: this.$lang.gac.table.group_type,
          industryName: this.$lang.gac.table.industry,
        },
        editableColumns: ["name"],
        sortable: ["name"],
        filterable: ["name"],
        // see the options API
      },
    };
  },
  validations: {
    gac: {
      name: {
        required,
      },
      type: {
        required,
      },
    },
  },

  created() {
    store.commit("showLoader", false); // Loader Off
    if (
      this.checkPermission("edit-gac-categories") === true ||
      this.checkPermission("delete-gac-categories") === true
    ) {
      this.columns.push("actions");
    }
    let self = this;
    axios
      .post(this.listUrlApi(this.industryModule), { type: "master" })
      .then(function (response) {
        if (response) {
          let responseData = response.data.data;
          self.industryOptions = responseData;
        } else {
          self.data = [];
        }
      });
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // self.data = this.loadData();
    self.statusOptions.push(
      { value: "Active", label: "Active" },
      { value: "InActive", label: "InActive" }
    );
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.gac.status = 1;
      } else {
        self.gac.status = 0;
      }
    },
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.gac.name = "";
      self.gac.type = "";
      self.gac.group_type = "";
      self.gac.industry = "";
      self.largeModal = true;
      self.gac.status = 1;
    },
    viewPostTableRow(id) {
      this.$router.push({ path: `gac/post/${id}` });
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.editId = item;
      axios
        .get(this.viewUrlApi(this.module, id), self.model)
        .then(function (response) {
          if (response) {
            let responseData = response.data.data;
            self.gac.name = responseData.name;
            if (responseData.status === "InActive") {
              self.gac.status = 0;
            } else {
              self.gac.status = 1;
            }
            self.gac.type = {
              id: responseData.type,
              label: responseData.type,
            };
            self.gac.industry = {
              id: responseData.industry_id,
              title: responseData.industry_name,
            };
            self.gac.group_type = {
              id: responseData.group_type,
              label: responseData.group_type,
            };

          } else {
            self.data = [];
          }
        });
      self.largeModal = true;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
            .delete(this.deleteUrlWeb(this.module, item), self)
            .then(function (response) {
              let responseData = response.data;
              if (responseData.code === 200) {
                self.$refs.myTable.refresh();
                self.alertMessage = responseData.message;
                self.dismissCountDownS = 10;
                self.messageColor = "success";
              } else {
                self.$refs.myTable.refresh();
                self.alertMessage = response.data.message;
                self.messageColor = "danger";
                self.dismissCountDownS = 10;
              }
            }).catch(function (error) {
              let data;
              if (error.response.data.error) {
                data = error.response.data.error.toString();
              } else {
                data = error.response.data.message;
              }
              self.alertMessage = data;
              self.messageColor = "danger";
              self.dismissCountDownS = 10;
            });
        }
      });
    },
    applyFilter() {
      console.log(this.gacFilter);
      store.commit("showLoader", true); // Loader Off
      this.$refs.myTable.customQueries = {
        type:
          this.gacFilter.type !== null &&
            Object.keys(this.gacFilter.type).length > 0 &&
            this.gacFilter.type.label != ""
            ? this.gacFilter.type.label
            : "",
        groupType:
          this.gacFilter.groupType !== null &&
            Object.keys(this.gacFilter.groupType).length > 0 &&
            this.gacFilter.groupType.label != ""
            ? this.gacFilter.groupType.label
            : "",
        industry:
          this.gacFilter.industry !== null &&
            Object.keys(this.gacFilter.industry).length > 0 &&
            this.gacFilter.industry.id > 0
            ? this.gacFilter.industry.id
            : "",
      };
      this.$refs.myTable.refresh();
    },
    resetFilter() {
      store.commit("showLoader", true); // Loader Off
      this.gacFilter.type = {};
      this.gacFilter.groupType = {};
      this.gacFilter.industry = {};
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
    onSubmit() {
      let self = this;
      this.$v.gac.$touch();
      if (this.$v.gac.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            let statusValue = "InActive";
            if (self.gac.status === 1) {
              statusValue = "Active";
            }
            const postData = {
              name: self.gac.name,
              status: statusValue,
              type: self.gac.type.label,
              group_type: self.gac.group_type.label,
              industry_id: self.gac.industry.id
            };
            axios
              .post(this.updateUrlweb(this.module, self.editId), postData)
              .then(function (response) {
                self.submitted = false; //Enabled Button
                if (response.data.code === 200) {
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                  self.largeModal = false;
                  self.messageColor = "success";
                  self.$refs.myTable.refresh();
                } else {
                  self.err_msg = response.data.message;
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                let data;
                if (error.response.data.error) {
                  data = error.response.data.error.toString();
                } else {
                  data = error.response.data.message;
                }
                self.err_msg = data;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            let statusValue = "InActive";
            if (self.gac.status === 1) {
              statusValue = "Active";
            }
            const postData = {
              name: self.gac.name,
              status: statusValue,
              type: self.gac.type.label,
              group_type: self.gac.group_type.label,
              industry_id: self.gac.industry.id
            };
            axios
              .post(this.createUrlWeb(this.module), postData)
              .then(function (response) {
                if (response.data.code === 200) {
                  self.alertMessage = response.data.message;
                  self.dismissCountDownS = 10;
                  self.largeModal = false;
                  self.gac.country = "";
                  self.$refs.myTable.refresh();
                  self.messageColor = "success";
                  self.submitted = false; //Enabled Button
                } else {
                  self.alertMessage = "";
                  self.err_msg = response.data.message;
                  self.submitted = false; //Enabled Button
                  self.dismissCountDown = 10;
                }
              })
              .catch(function (error) {
                let data;
                if (error.response.data.error) {
                  data = error.response.data.error.toString();
                } else {
                  data = error.response.data.message;
                }
                self.err_msg = data;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              });
          }, 500);
        }
      }
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
